import axios from 'axios'

const domain = process.env.VUE_APP_URL_API

const axiosInstance = axios.create({
  domain,
  baseURL: process.env.VUE_APP_URL_API,
})

axiosInstance.interceptors.request.use(function (config) {
  const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}')
  if (localStorageUser && localStorageUser.access_token) {
    config.headers.Authorization = `Bearer ${localStorageUser.access_token}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(function (response) {
  if (response.status === 401) {
    localStorage.removeItem('user')
    window.location.href = '/login'
  }

  return response
})

export default axiosInstance
