import router from '../../../router'
import axiosAPI from '../../../../axios-API'
import handleResponse from '../../../services/responseHandler'

function login ({ commit, state }, { username, password, rememberUser }) {
  commit('LOGIN_REQUEST', { username })

  const authentication = Buffer.from(`${username}:${password}`).toString('base64')

  return axiosAPI.post('/v2/auth/u/login', {
    grant_type: 'client_credentials',
    scope: 'dashboard',
   }, {
    headers: {
      Authorization: `Basic ${authentication}`,
    },
  })
    .then(user => {
      if (user.data.access_token) {
        user.data.user_data.access_token = user.data.access_token
        if (rememberUser) {
          localStorage.setItem('rememberUser', JSON.stringify(user.data.user_data))
          delete user.data.user_data.username
          delete user.data.user_data.password
          localStorage.setItem('user', JSON.stringify(user.data.user_data))
        } else {
          delete user.data.user_data.username
          delete user.data.user_data.password
          localStorage.setItem('user', JSON.stringify(user.data.user_data))
          localStorage.removeItem('rememberUser')
        }
      }
      return user.data
    })
    .then(user => {
      commit('LOGIN_SUCCESS', user.user_data)
      router.push(state.destination || '/')
    })
}

function snackbarError ({ commit }, val) {
  commit('ERROR_SNACKBAR', val)
}

function logout ({ commit }) {
  localStorage.removeItem('user')
  commit('LOGOUT')
}

function register ({ commit }, user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }

  commit('REGISTER_REQUEST', user)

  axiosAPI.post('/users/register', requestOptions).then(handleResponse)
    .then(
      user => {
        commit('REGISTER_SUCCESS', user)
        router.push('/login')
        setTimeout(() => {
          // display success message after route change completes
        })
      },
      error => {
        commit('REGISTER_FAILURE', error)
      },
    )
}

export default {
  login,
  logout,
  register,
  snackbarError,
}
