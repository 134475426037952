import axiosAPI from '../../../../axios-API'
import moment from 'moment'

async function getCustomers ({ commit }) {
  commit('SET_UPDATE_START')

  return axiosAPI.get('/v2/manage/s/customers/verif-status')
    .then((res) => {
      const offlineCustomers = res.data.result.off
      const onlineCustomers = res.data.result.on
      commit('SET_PING_STATISTICS', { onlineCustomers: onlineCustomers, offlineCustomers: offlineCustomers, updatedAt: moment().format('LLL') })
      commit('SET_UPDATE_END')
      return res.data
    })
    .catch((err) => {
      console.error('Could not get customer list.\n', err.message)
      commit('SET_UPDATE_END')
    })
}

async function getCustomer ({ commit }, customerId) {
  return await axiosAPI.get(`/v2/manage/s/customers/${customerId}`).then((res) => res.data.result)
}

let reqLOCK = false

async function listCustomers ({ commit }) {
  if (reqLOCK) return
  reqLOCK = true

  try {
    return await axiosAPI.get('/v2/manage/s/customers').then((res) => {
      commit('SET_CUSTOMERS', res.data.results.map(data => ({
        ...data,
        version: data.enabled ? data.tentacle.version || 'Não identificada' : 'Desabilitado',
        runMode: data.enabled ? data.tentacle.runMode || 'Não identificado' : 'Desabilitado',
      })))
      return res.status
    })
  } finally {
    reqLOCK = false
  }
}

async function sendCustomerForm ({ commit }, data) {
  // If data has _id, edit customer
  if (data._id) {
    return await axiosAPI.put(`/v2/manage/s/customers/${data._id}`, data).then((res) => res.status)
  }
  // else create new
  return await axiosAPI.post('/v2/manage/s/customers', data)
}

async function getTentacleConfigCredential ({ commit }, customerId) {
  // If data has _id, edit customer
  if (customerId) {
    return await axiosAPI.get(`/v2/manage/s/customers/${customerId}/config`, {}).then((res) => res.data.token)
      .catch((err) => ({ err: err.error }))
  } else {
    return { err: 'Obrigatório informar id do cliente.' }
  }
}

export default {
  getCustomer,
  getCustomers,
  listCustomers,
  sendCustomerForm,
  getTentacleConfigCredential,
}
