import { authHeader } from '../../../services/auth-header'
import axiosAPI from '../../../../axios-API'

async function getAll ({ commit }) {
  commit('GET_ALL_REQUEST')

  const config = {
    headers: authHeader(),
  }
  return await axiosAPI.get('/v2/manage/s/u', config).then(item => item.data.result)
    .then(
      users => commit('GET_ALL_SUCCESS', users),
      error => commit('GET_ALL_FAILURE', error),
    )
}

function changePassword ({ commit }, [newPassword, id]) {
  const body = {
      password: newPassword,
  }
  axiosAPI.put(`/v2/manage/s/u/${id}/recover-password`, body)
}

async function findUser ({ commit }, { name, username }) {
  const query = name ? `name=${name}` : `username=${username}`

  await axiosAPI.get(`/v2/manage/s/u?${query}`).then((res) => {
    commit('KEEP_FOUND_USER', res.data.results)
  })
}

let reqLOCK = false
async function getUser ({ commit }, id) {
  if (reqLOCK) return
  reqLOCK = true

  try {
    return await axiosAPI.get(`/v2/manage/s/u/${id}`).then((res) => {
      return res.data.result
    })
  } finally {
    reqLOCK = false
  }
}

function deleteUser ({ commit }, id) {
  return axiosAPI.delete(`/v2/manage/s/u/${id}`)
}

function updateUser ({ commit }, user) {
  return axiosAPI.put(`/v2/manage/s/u/${user._id}`, user)
}

export default {
  getUser,
  getAll,
  deleteUser,
  changePassword,
  findUser,
  updateUser,
}
